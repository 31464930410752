import React from 'react';
import Fade from 'react-reveal/Fade';
import Box from '../Atoms/Box';

import LogoLarge from '../../assets/icons/logo-large.svg';
import GoldTriangle from '../../assets/icons/home-gold-triangle.svg';
import FlexBox from '../Atoms/FlexBox';

function HomeDeco(props) {
  return (
    <FlexBox posAb top={0} left={0} width="100vw" height="100vh" zIndex={10}>
      {/*<Box posAb bottom={0} left={0} lineHeight={0} fontSize="0px" width={[100, 100, 130, 'auto']}>*/}
      {/*  <Fade delay={800}>*/}
      {/*    <GoldTriangle />*/}
      {/*  </Fade>*/}
      {/*</Box>*/}
      <Box lineHeight={0} width={['55%', '55%', '45%', '25%']}>
        <Fade delay={1000}>
          <LogoLarge />
        </Fade>
      </Box>
    </FlexBox>
  );
}

export default HomeDeco;
