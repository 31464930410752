import React, { Component } from 'react';
import styled from 'styled-components';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import ImageSwiper from '../ImageSwiper';
import HomeDeco from './HomeDeco';

const FullScreenContainer = styled(Container)`
  overflow: hidden;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

class Hero extends Component {
  handleResize = () => {
    const vh = window.innerHeight * 0.01;
    if (!document.getElementById('__layout')) {
      return;
    }
    document.getElementById('__layout').style.setProperty('--vh', `${vh}px`);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.getElementById('__layout').style.setProperty('--vh', '');
  }

  render() {
    const { hero } = this.props;

    return (
      <Section p={0}>
        <FullScreenContainer id="fullscreen-wrapper" fluid>
          <ImageSwiper list={hero} />
          <HomeDeco />
        </FullScreenContainer>
      </Section>
    );
  }
}

export default Hero;
