import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import Hero from '../components/Home/Hero';

import { bringUpSubscribsionModal } from '../utilis/helpers';

// ********************************* UI Template (Shared with CMS) *********************************
export const IndexPageTemplate = ({ title, description, hero, cms = false }) => (
  <div>
    <PageHelmet title={title} description={description} />
    <Hero hero={hero} />
  </div>
);

// ********************************* Render page *********************************
const IndexPage = ({ data }) => {
  const displayed = false;

  const { frontmatter } = data.markdownRemark;

  useEffect(() => {
    const intervalChecker = setInterval(() => {
      if (!displayed && typeof window !== 'undefined' && window.dojoRequire) {
        bringUpSubscribsionModal();
        clearInterval(intervalChecker);
      }
    }, 500);
  }, []);

  return <IndexPageTemplate {...frontmatter} />;
};

export default IndexPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query IndexPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        hero {
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          mobileImg {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
